import { apolloClientCC as apolloClient } from "../../../vue-apollo";
// src/store/modules/tv/storeAddContent.js
import { exQue, exMut } from "@/utils/gql";
import {
  SAVE_CHANNEL_LAYOUT,
  EDIT_CHANNEL_LAYOUT,
  DELETE_FILE_REPOSITORIO,
} from "../../../apollo/mutations";
import {
  GET_ONE_CANAL,
  GET_PAGINAS_FILES_REPOSITORIO,
  GET_TOTAL_FILES_REPOSITORIO,
  GET_ALL_WIDGETS,
  GET_NOTICIAS_OPCIONES,
} from "../../../apollo/queries";
import { SAVE_FILE_REPO } from "../../../apollo/subscriptions";
import moment from "moment";
import { isImage } from "../../../utils/formats.js";
import { VideoAdapter } from "../../../models/VideoAdapter";

function filteredRepoItems(items, pagination, page) {
  return items
    .filter((item) => pagination[page].ids?.includes(item.id_archivo))
    .reverse();
}

export default {
  namespaced: true,
  state: {
    channelLayout: {
      id_canal: "",
      id_layout: "",
      logo: {},
      url_logo: "",
      videos: [],
      tiempo_videos: "00:00:00",
      banners: [],
      bannersH: [],
      color: "",
      widgets: {},
      apiNews: [],
      timePerBanner: 4,
      timePerBannerH: 4,
    },
    baseChannelLayout: {
      id_canal: "",
      id_layout: "",
      logo: {},
      url_logo: "",
      videos: [],
      tiempo_videos: "00:00:00",
      banners: [],
      bannersH: [],
      color: "",
      widgets: {},
      apiNews: [],
      timePerBanner: 4,
      timePerBannerH: 4,
    },
    selectedMediaList: [],
    imagesListTime: 4,
    allRepoVideos: [],
    allRepoVideosExternos: [],
    allRepoBanners: [],
    allRepoBannersH: [],
    allRepoImages: [],
    repoVideosPagination: [],
    repoExternoPagination: [],
    repoBannersPagination: [],
    repoBannersHPagination: [],
    repoImagesPagination: [],
  },

  getters: {
    isLayoutValidForBack(state) {
      let valid = true;
      if (
        state.channelLayout.id_layout == 1 ||
        state.channelLayout.id_layout == 2
      ) {
        valid &= Object.keys(state.channelLayout.widgets).length >= 3;
        valid &= state.channelLayout.videos.length > 0;
        valid &= state.channelLayout.banners.length > 0;
        valid &= state.channelLayout.color !== "";
        valid &=
          state.channelLayout.url_logo !== "" ||
          Object.keys(state.channelLayout.logo).length > 0;
        if (state.channelLayout.id_layout == 2) {
          valid &= state.channelLayout.apiNews.length > 0;
        }
      } else if (state.channelLayout.id_layout == 3) {
        valid &= Object.keys(state.channelLayout.widgets).length >= 1;
        valid &= state.channelLayout.videos.length > 0;
        valid &= state.channelLayout.color !== "";
        valid &=
          state.channelLayout.url_logo !== "" ||
          Object.keys(state.channelLayout.logo).length > 0;
        valid &= state.channelLayout.apiNews.length !== 0;
      }
      return valid;
    },

    repoVideosFiltered: (state) => (page) => {
      return filteredRepoItems(
        state.allRepoVideos,
        state.repoVideosPagination,
        page
      );
    },
    repoVideosExternoFiltered: (state) => (page) => {
      return filteredRepoItems(
        state.allRepoVideosExternos,
        state.repoExternoPagination,
        page
      );
    },
    repoBannersFiltered: (state) => (page) => {
      return filteredRepoItems(
        state.allRepoBanners,
        state.repoBannersPagination,
        page
      );
    },
    repoBannersHFiltered: (state) => (page) => {
      return filteredRepoItems(
        state.allRepoBannersH,
        state.repoBannersHPagination,
        page
      );
    },
    repoImagesFiltered: (state) => (page) => {
      return filteredRepoItems(
        state.allRepoImages,
        state.repoImagesPagination,
        page
      );
    },

    paginationDataForRepoVideos(state) {
      return {
        page_count:
          state.repoVideosPagination.length == 0
            ? 1
            : state.repoVideosPagination.length,
      };
    },
    paginationDataForRepoVideosExterno(state) {
      return {
        page_count:
          state.repoExternoPagination.length == 0
            ? 1
            : state.repoExternoPagination.length,
      };
    },
    paginationDataForRepoImages(state) {
      return {
        page_count:
          state.repoImagesPagination.length == 0
            ? 1
            : state.repoImagesPagination.length,
      };
    },
  },

  mutations: {
    setSelectedMediaList(state, media) {
      media.forEach((m) => {
        state.selectedMediaList.push(m);
      });
    },
    removeSelectedMediaList(state, index) {
      state.selectedMediaList.splice(index, 1);
    },
    cleanSelectedMediaList(state) {
      state.selectedMediaList = [];
    },
    imagesListTimeUpdateState(state, time) {
      state.imagesListTime = time;
    },
    resetChannelContent(state) {
      state.channelLayout = JSON.parse(JSON.stringify(state.baseChannelLayout));
    },
    setChannelLayoutLogo(state, logo) {
      state.channelLayout.logo = logo;
    },
    deleteChannelLayoutLogo(state) {
      state.channelLayout.logo = {};
      state.channelLayout.url_logo = "";
    },
    setChannelLayoutVideos(state, data_) {
      state.channelLayout.videos = data_.videos.slice();
      state.channelLayout.tiempo_videos = data_.tiempo_total;
    },
    setChannelLayoutBanners(state, data_) {
      state.channelLayout.banners = data_.banners.slice();
      state.channelLayout.timePerBanner = data_.timePerBanner;
    },
    setChannelLayoutBannersH(state, data_) {
      state.channelLayout.bannersH = data_.banners.slice();
      state.channelLayout.timePerBannerH = data_.timePerBanner;
    },
    setChannelLayoutColor(state, color) {
      state.channelLayout.color = color;
    },
    setChannelLayoutWidgets(state, data_) {
      if (state.channelLayout.widgets[data_.rank]) {
        Object.assign(state.channelLayout.widgets[data_.rank], data_.widget);
      } else {
        state.channelLayout.widgets[data_.rank] = { ...data_.widget };
      }
      state.channelLayout.widgets[data_.rank].tiempo_ejecucion =
        data_.tiempo_ejecucion;
    },
    setChannelLayoutApiNews(state, data_) {
      // push cuando sean múltiples apis:
      data_[0].id_widget = state.channelLayout.apiNews[0]?.id_widget ?? "0";
      state.channelLayout.apiNews = data_;
    },
    setChannelLayoutId(state, id) {
      state.channelLayout.id_canal = id;
    },
    setChannelLayoutDesign(state, layout) {
      state.channelLayout.id_layout = layout.id;
    },
    getCanal(state, response) {
      state.channelLayout = JSON.parse(JSON.stringify(state.baseChannelLayout));

      const hms_to_sec = (hms) => {
        let a = hms.split(":");
        return +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
      };

      // Metadata
      state.channelLayout.id_canal = response.id_canal;
      state.channelLayout.id_layout =
        response.lista_reproduccion[0].layout.id_layout;
      state.channelLayout.url_logo = response.lista_reproduccion[0].url_logo;
      state.channelLayout.color = response.lista_reproduccion[0].color;
      state.channelLayout.id_lista = response.lista_reproduccion[0].id_lista;

      // Videos
      state.channelLayout.videos = response.lista_reproduccion[0].videos[0]
        ? response.lista_reproduccion[0].videos
            .sort((a, b) => +a.orden - +b.orden)
            .map((video) => {
              if (video.archivo != null) {
                if (isImage(video.archivo.url_archivo)) {
                  video.archivo.tiempo_video = video.tiempo_ejecucion;
                }
              }

              return VideoAdapter.from_get_canal(video);
            })
        : [];

      state.channelLayout.tiempo_videos = response.lista_reproduccion[0]
        .videos[0]
        ? response.lista_reproduccion[0].videos[0].total_tiempo
        : "00:00:00";

      // Banners
      state.channelLayout.banners = response.lista_reproduccion[0].banners[0]
        ? response.lista_reproduccion[0].banners
            .sort((a, b) => +a.orden - +b.orden)
            .map((banner) => {
              return { id_file: banner.id_file, ...banner.archivo };
            })
        : [];
      state.channelLayout.timePerBanner = response.lista_reproduccion[0]
        .banners[0]
        ? hms_to_sec(response.lista_reproduccion[0].banners[0].tiempo_ejecucion)
        : 4;

      state.channelLayout.bannersH = response.lista_reproduccion[0]
        .banners_horizontales[0]
        ? response.lista_reproduccion[0].banners_horizontales
            .sort((a, b) => +a.orden - +b.orden)
            .map((banner) => {
              return { id_file: banner.id_file, ...banner.archivo };
            })
        : [];
      state.channelLayout.timePerBannerH = response.lista_reproduccion[0]
        .banners_horizontales[0]
        ? hms_to_sec(
            response.lista_reproduccion[0].banners_horizontales[0]
              .tiempo_ejecucion
          )
        : 4;

      // Widgets (tratamos widgets de noticias de distinta forma dada su posición en el layout)
      const frontWidgets = response.lista_reproduccion[0].widgets
        .filter((widget) => !widget.api || widget.api.id_api !== 4)
        .map(({ id_widget, tipo_widget, modulo, api, tiempo_ejecucion }) => {
          const dias_accidente =
            api?.id_api == 3
              ? api.dias_accidente.dias_accidente == null ||
                api.dias_accidente.dias_accidente == ""
                ? "1"
                : api.dias_accidente.dias_accidente
              : "0";
          return {
            id_objeto: tipo_widget == "1" ? modulo?.id_modulo : api?.id_api,
            tipo_widget: tipo_widget,

            id_widget: id_widget,
            imagen_widget:
              tipo_widget == "1" ? modulo?.url_imagen : api?.url_imagen,
            nombre_widget:
              tipo_widget == "1" ? modulo?.nombre_modulo : api?.nombre_api,

            tiempo_ejecucion: hms_to_sec(tiempo_ejecucion),
            dias_accidente,
            ubicaciones_clima: api?.id_api == 1 ? api.clima_api : [],
            cuenta_regresiva: api?.id_api == 5 ? api.cuenta_regresiva : [],
          };
        });

      state.channelLayout.apiNews = response.lista_reproduccion[0]
        .widgets_rrss[0]
        ? response.lista_reproduccion[0].widgets_rrss
        : [];

      // enumera en un obj los elementos de frontWidgets: { '0': widget0, '1':  ... }
      state.channelLayout.widgets = { ...frontWidgets };
    },
    getChannelStateFromLS(state) {
      if (localStorage.getItem("channelLayout")) {
        Object.assign(
          state.channelLayout,
          JSON.parse(localStorage.getItem("channelLayout"))
        );
      }
    },
    setAllRepoFiles(state, fileObjArr) {
      if (fileObjArr.length === 0) return;
      if (fileObjArr[0].detalle_tipoa.tipo_archivo.id_tipo === "1") {
        state.allRepoVideos = fileObjArr;
      }
      if (fileObjArr[0].detalle_tipoa.tipo_archivo.id_tipo === "2") {
        state.allRepoBanners = fileObjArr;
      }
      if (fileObjArr[0].detalle_tipoa.tipo_archivo.id_tipo === "3") {
        state.allRepoBannersH = fileObjArr;
      }
      if (fileObjArr[0].detalle_tipoa.tipo_archivo.id_tipo === "4") {
        state.allRepoImages = fileObjArr;
      }
      if (fileObjArr[0].detalle_tipoa.tipo_archivo.id_tipo === "5") {
        state.allRepoVideosExternos = fileObjArr;
      }
    },
    deleteRepoFile(state, resp) {
      const { deleted, type } = resp;

      if (type === 1 || type === "1") {
        state.allRepoVideos = state.allRepoVideos.filter(
          (video) => video.id_archivo != deleted.id_archivo
        );
      }
      if (type === 2 || type === "2") {
        state.allRepoBanners = state.allRepoBanners.filter(
          (banner) => banner.id_archivo != deleted.id_archivo
        );
        state.allRepoBannersH = state.allRepoBannersH.filter(
          (banner) => banner.id_archivo != deleted.id_archivo
        );
      }
      if (type === 3 || type === "3") {
        state.allRepoImages = state.allRepoImages.filter(
          (img) => img.id_archivo != deleted.id_archivo
        );
      }

      if (type === 7 || type === "7") {
        state.allRepoVideosExternos = state.allRepoVideosExternos.filter(
          (video) => video.id_archivo != deleted.id_archivo
        );
      }
    },
    /**
     * Setea la paginacion en el state segun el tipo de archivo
     * @param {Object} state - El state del modulo
     * @param {Object} { pagination, payload } - El objeto con la paginacion y el objeto con la informacion de la peticion
     * @param {Array} pagination - El arreglo de paginacion
     * @param {Object} payload - El objeto con la informacion de la peticion
     * @param {Number} payload.tipoArchivo - El tipo de archivo: 1: video, 2: banner, 5: bannerH, 6: imagen
     */
    setPagination(state, { pagination, payload }) {
      if (payload.tipoArchivo === "1") {
        state.repoVideosPagination = pagination;
      } else if (payload.tipoArchivo === "6") {
        state.repoImagesPagination = pagination;
      } else if (payload.tipoArchivo === "7") {
        state.repoExternoPagination = pagination;
      } else if (payload.tipoArchivo === "2") {
        state.repoBannersPagination = pagination;
      } else if (payload.tipoArchivo === "5") {
        state.repoBannersHPagination = pagination;
      }
    },
  },

  actions: {
    setSelectedMediaList({ commit }, mediaList) {
      commit("setSelectedMediaList", mediaList);
    },
    removeSelectedMediaList({ commit }, mediaList) {
      commit("removeSelectedMediaList", mediaList);
    },
    cleanSelectedMediaList({ commit }) {
      commit("cleanSelectedMediaList");
    },
    imagesListTimeUpdate({ commit }, time) {
      commit("imagesListTimeUpdateState", time);
    },
    getChannelStateFromLS({ commit }) {
      commit("getChannelStateFromLS");
    },
    resetChannelContent({ commit }) {
      commit("resetChannelContent");
    },
    setChannelLayoutLogo({ commit }, logo) {
      commit("setChannelLayoutLogo", logo);
    },
    deleteChannelLayoutLogo({ commit }) {
      commit("deleteChannelLayoutLogo");
    },
    setChannelLayoutVideos({ commit }, data_) {
      commit("setChannelLayoutVideos", data_);
    },
    setChannelLayoutBanners({ commit }, data_) {
      if (data_.isBannerH) {
        commit("setChannelLayoutBannersH", data_);
      } else {
        commit("setChannelLayoutBanners", data_);
      }
    },
    setChannelLayoutColor({ commit }, color) {
      commit("setChannelLayoutColor", color);
    },
    setChannelLayoutWidgets({ commit }, data_) {
      commit("setChannelLayoutWidgets", data_);
    },
    setChannelLayoutApiNews({ commit }, data_) {
      commit("setChannelLayoutApiNews", data_);
    },
    setChannelLayoutId({ commit }, id_canal) {
      commit("setChannelLayoutId", id_canal);
    },
    setChannelLayoutDesign({ commit }, layout) {
      commit("setChannelLayoutDesign", layout);
    },
    mapVideos(_, { videos, state }) {
      return videos.map((video) => {
        return {
          id_archivo_lista: +video.id_file ?? 0,
          id_archivo_repositorio: +video.id_archivo, // los que vienen del repo
          id_video: +video.id_video, // los que vienen de Videos
          tipo_archivo: "1",
          tiempo_ejecucion: video.tiempo_video,
          total_tiempo: state.channelLayout.tiempo_videos, // tiempo total de playlist
          fecha_activacion: null,
        };
      });
    },
    mapBanners(_, { banners, state }) {
      return banners.map((banner) => {
        return {
          id_archivo_lista: +banner.id_file ?? 0,
          id_archivo_repositorio: +banner.id_archivo,
          id_video: null,
          tipo_archivo: "2",
          tiempo_ejecucion: moment()
            .startOf("day")
            .add(state.channelLayout.timePerBanner, "seconds")
            .format("HH:mm:ss"),
          total_tiempo: moment()
            .startOf("day")
            .add(
              state.channelLayout.timePerBanner *
                state.channelLayout.banners.length,
              "seconds"
            )
            .format("HH:mm:ss"),
          fecha_activacion: null,
        };
      });
    },
    mapBannersHorizontales(_, { bannersH, state }) {
      return bannersH.map((banner) => {
        return {
          id_archivo_lista: +banner.id_file ?? 0,
          id_archivo_repositorio: +banner.id_archivo,
          id_video: null,
          tipo_archivo: "5",
          tiempo_ejecucion: moment()
            .startOf("day")
            .add(state.channelLayout.timePerBannerH, "seconds")
            .format("HH:mm:ss"),
          total_tiempo: moment()
            .startOf("day")
            .add(
              state.channelLayout.timePerBannerH *
                state.channelLayout.bannersH.length,
              "seconds"
            )
            .format("HH:mm:ss"),
          fecha_activacion: null,
        };
      });
    },
    /**
     * Mapea los widgets para el canal
     * @param {ActionContext} context - El contexto de Vuex
     * @param {Object} param - Parámetros
     * @param {Array} param.widgets - Lista de widgets a mapear
     * @returns {Array} Widgets mapeados
     */
    mapWidgets(_, { widgets }) {
      return widgets.map(
        ({
          id_widget,
          id_objeto,
          tipo_widget,
          tiempo_ejecucion,
          ubicaciones_clima,
          dias_accidente,
          id_rrss,
          cuenta_regresiva,
        }) => {
          return {
            id_widget: +id_widget ?? 0,
            id_objeto: +id_objeto,
            tipo_widget,
            tiempo_ejecucion: moment()
              .startOf("day")
              .add(tiempo_ejecucion, "seconds")
              .format("HH:mm:ss"),
            ubicaciones_clima:
              id_objeto == 1
                ? ubicaciones_clima.map((locObj) => ({
                    ubicacion: locObj.ubicacion,
                    latitud: locObj.latitud,
                    longitud: locObj.longitud,
                  }))
                : null,
            dias_accidente: id_objeto == 3 ? dias_accidente : null,
            id_rrss: id_objeto == 4 ? +id_rrss : null,
            cuenta_regresiva:
              id_objeto == 5
                ? cuenta_regresiva.map(({ titulo, fecha, hora }) => ({
                    titulo,
                    fecha,
                    hora,
                  }))
                : [],
          };
        }
      );
    },

    /**
     * Configura el layout del canal
     * @param {ActionContext} context - El contexto de Vuex
     * @param {Object} data_ - Datos del layout
     * @param {String} data_.id_lista - ID de la lista de reproducción
     * @returns {Promise<Object>} El layout configurado
     */
    async setChannelLayout({ dispatch, state }, data_) {
      //FIX TEMPORAL
      state.channelLayout.apiNews = [state.channelLayout.apiNews[0]];

      let widgetsBeforeMap = [1, 2, 3].includes(+state.channelLayout.id_layout)
        ? Object.entries(state.channelLayout.widgets)
            .sort((entryA, entryB) => +entryA[0] - +entryB[0])
            .map((entry) => entry[1])
        : [];

      widgetsBeforeMap =
        state.channelLayout.id_layout == 3
          ? widgetsBeforeMap.slice(0, 2)
          : widgetsBeforeMap;
      widgetsBeforeMap = [2, 3].includes(+state.channelLayout.id_layout)
        ? widgetsBeforeMap.concat(
            state.channelLayout.apiNews.map((el) => ({
              ...el,
              id_objeto: 4,
              tipo_widget: "2",
              nombre_widget: "RRSS",
              tiempo_ejecucion: "0",
            }))
          )
        : widgetsBeforeMap;

      const widgets = await dispatch("mapWidgets", {
        widgets: widgetsBeforeMap,
      });
      const videos = await dispatch("mapVideos", {
        videos: state.channelLayout.videos,
        state,
      });
      const banners = await dispatch("mapBanners", {
        banners:
          state.channelLayout.id_layout == 3 ? [] : state.channelLayout.banners,
        state,
      });
      const banners_horizontales = await dispatch("mapBannersHorizontales", {
        bannersH:
          state.channelLayout.id_layout == 4
            ? state.channelLayout.bannersH
            : [],
        state,
      });

      const payload = {
        id_canal: +state.channelLayout.id_canal,
        lista: {
          id_lista: +data_.id_lista ?? undefined,
          id_layout: +state.channelLayout.id_layout,
          logo: state.channelLayout.logo.src
            ? state.channelLayout.logo.src
            : "",
          formato_logo: "image/" + String(state.channelLayout.logo.format),
          color: state.channelLayout.color,
          videos,
          banners,
          banners_horizontales,
          widgets,
        },
      };
      const mutation = data_.id_lista
        ? EDIT_CHANNEL_LAYOUT
        : SAVE_CHANNEL_LAYOUT;
      const params = {
        mutation,
        variables: payload,
      };

      let response = null;
      try {
        const res = await apolloClient.mutate(params);
        if (!res.loading)
          response = data_.id_lista
            ? res.data.UpdateListaReproduccion
            : res.data.SaveListaReproduccion;

        //response = await exMut(mutation, payload);
      } catch (e) {
        console.log("Error:", e.message);
      }

      return response;
    },

    /**
     * Obtiene la información de un canal
     * @param {ActionContext} context - El contexto de Vuex
     * @param {Object} data_ - Filtros para obtener el canal
     * @returns {Promise<Object>} Información del canal
     */
    async getCanal({ commit }, data_) {
      let response = [];
      try {
        response = await exQue(GET_ONE_CANAL, data_, true);
        commit("getCanal", response);
      } catch (e) {
        console.log(e.networkError.result.errors);
        response = null;
      }
      return response;
    },

    async getWidgets() {
      let response = null;
      try {
        response = await exQue(GET_ALL_WIDGETS, {}, true);
      } catch (e) {
        console.log(e);
      }

      return response;
    },

    async getNoticiasOpciones() {
      let response = null;
      try {
        response = await exQue(GET_NOTICIAS_OPCIONES, {}, true);
      } catch (e) {
        console.log(e);
      }
      return response;
    },

    /**
     * Sube un archivo mediante suscripción
     * @param {ActionContext} context - El contexto de Vuex
     * @param {Object} data_ - Datos del archivo
     * @param {String} data_.id_empresa - ID de la empresa
     * @param {String} data_.id_usuario - ID del usuario
     * @param {String} data_.descripcion - Descripción del archivo
     * @param {String} data_.tipo_archivo - Tipo de archivo
     * @param {Object} data_.file - Archivo a subir
     * @returns {Observable} Suscripción de apollo
     */
    uploadFileSubscription(_, data_) {
      const { 
        id_empresa, 
        id_usuario, 
        tipo_archivo, 
        file, 
        descripcion 
      } = data_;
      const duration = new Date(file.duration * 1000)
        .toISOString()
        .slice(11, 19);
      const payload = {
        id_empresa,
        id_usuario,
        descripcion_archivo: descripcion || "",
        tiempo: String(duration),
        tipo_archivo: tipo_archivo,
        nombre_archivo: file.name || "",
        archivo_b64: file.src,
        formato_b64: file.type,
      };
      const params = {
        query: SAVE_FILE_REPO,
        variables: payload,
      };

      return apolloClient.subscribe(params);
    },

    async deleteFileRepositorio({ commit }, { id_archivo, type }) {
      let res = null;
      try {
        res = await exMut(DELETE_FILE_REPOSITORIO, { id_archivo });
        if (res) {
          commit("deleteRepoFile", { deleted: res, type });
        }
      } catch (error) {
        console.error("Error en deleteFileRepositorio:", error);
      }
      return res;
    },

    /**
     * Obtiene los archivos del repositorio
     * @param {ActionContext} context - El contexto de Vuex
     * @param {Object} data_ - Filtros de búsqueda
     * @returns {Promise<Array>} Lista de archivos
     */
    async getRepoFiles(_, data_) {
      let response = null;
      try {
        response = await exQue(GET_PAGINAS_FILES_REPOSITORIO, data_);
      } catch (e) {
        console.log("response de getRepoFiles: ", e);
      }
      return response;
    },

    /**
     * Obtiene la paginación de archivos
     * @param {ActionContext} context - El contexto de Vuex
     * @param {Object} data_ - Filtros de paginación
     * @returns {Promise<Object>} Datos de paginación
     */
    async getFilesPagination({ commit }, data_) {
      let response = null;
      try {
        response = await exQue(GET_TOTAL_FILES_REPOSITORIO, data_, true);
        commit("setPagination", { pagination: response, payload: data_ });
      } catch (e) {
        console.log("error en getFilesPagination: ", e);
      }
      return response;
    },

    async getAllRepoFiles({ dispatch, commit }, data_) {
      Object.assign(data_, {
        nombreArchivo: "",
      });
      const filesIds = await dispatch("getFilesPagination", data_);
      let allFiles = null;

      if (filesIds !== null) {
        let repoFilesIds = filesIds.reduce(
          (ids, page) => ids.concat(page.ids),
          []
        );
        allFiles = await dispatch("getRepoFiles", {
          idEmpresa: data_.idEmpresa,
          idArchivo: repoFilesIds,
        });
        commit("setAllRepoFiles", allFiles);
      } else {
        console.log(
          "getAllRepoFiles. error en getFilesPagination. respuesta null"
        );
      }
      return allFiles;
    },
  },
};
